import React, { useState, useEffect } from "react";
import "./styles/submit.css";

function SubmitAnimation() {

  return <div className="container">
          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="100" cy="100" r="50" fill="none"/>
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
        </div>
}

export default SubmitAnimation;

/*import React, { Component } from "react";
import "./styles/submit.css";

class SubmitAnimation extends Component {
  state = {
    isVisible: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isVisible: false });
    }, 2500);
  }

  render() {
    return this.state.isVisible ? (
      <div class="container">
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="100" cy="100" r="50" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
      </div>

    ) : null;
  }
}

export default SubmitAnimation;
*/

import React from 'react';
import "../styles/content-page.css";

class Privacy extends React.Component {
    render() {
        return (
            <div className="page-wrapper-div">
                <h1>Privacy Policy</h1>
                <p>This Privacy Policy outlines the data collection and handling practices of **Potential**. We take the privacy of our users seriously and are committed to protecting the personal information they provide to us when using our platform.</p>
                
                <h2>Information We Collect</h2>
                <p>We collect the following types of information when you use our service:</p>
                <ul>
                    <li><strong>Personal Information:</strong> When you log in using your Gmail account, we collect your email address, Google ID, and basic profile information such as your name and profile picture.</li>
                    <li><strong>Content You Share:</strong> Any content you choose to share on the platform, such as text, images, or links to videos, is stored and used to enhance the user experience.</li>
                    <li><strong>Automatically Collected Information:</strong> We collect certain information automatically when you use our service, such as your IP address, browser type, and usage statistics.</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <p>We use the information we collect for the following purposes:</p>
                <ul>
                    <li>To operate and improve the platform</li>
                    <li>To provide personalized content and recommendations</li>
                    <li>To communicate with you regarding updates, features, and other information</li>
                    <li>To ensure the security and integrity of the platform</li>
                </ul>

                <h2>Protection of Your Information</h2>
                <p>We take reasonable precautions to safeguard your information from unauthorized access, use, or disclosure. Sensitive information is encrypted, and our data is stored on secure servers with firewall protection.</p>

                <h2>Cookies and Tracking Technologies</h2>
                <p>We use cookies and similar tracking technologies to enhance your experience on our platform by remembering your preferences. You can control the use of cookies in your browser settings, but please note that disabling cookies may impact your experience.</p>

                <h2>Third-Party Sharing</h2>
                <p>We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share your information with trusted third-party service providers who assist us in operating the platform, provided they agree to keep your information confidential. We may also disclose your information when required by law or to protect our rights.</p>

                <h2>Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will take effect immediately upon posting.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about our Privacy Policy, please contact us.</p>
            </div>
        );
    }
}

export default Privacy;

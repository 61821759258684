import React from 'react'
import "../styles/content-page.css";


class TermofUse extends React.Component {
  
    render() {

        return (
            <div class="page-wrapper-div">
                <h1>Terms of Use</h1>
                <p>Welcome to Xlearner. These Terms of Use (the "Terms") govern your use of the Xlearner website, including all its features and services (the "Service"). By using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.</p>

                <h2>1. User Content</h2>
                <p>The Service allows users to upload, submit, and share content, including tutorials, guides, and other materials (the "User Content"). You retain all rights in and to the User Content you make available through the Service, subject to the rights granted to us in these Terms.</p>
                <p>By making any User Content available through the Service, you grant to us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, copy, modify, create derivative works based on, distribute, publicly display, and otherwise exploit your User Content in connection with the Service and our business, including without limitation for promoting and redistributing part or all of the Service (and derivative works thereof) in any media formats and through any media channels.</p>
                <p>You are solely responsible for the User Content you make available through the Service, and you represent and warrant that you either own or have the necessary rights and permissions to use and authorize us to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all User Content to enable inclusion and use of the User Content in the manner contemplated by the Service and these Terms.</p>

                <h2>2. User Conduct</h2>

                <p>You agree not to use the Service to upload, post, transmit, or otherwise make available any User Content that:</p>
                <ul>
                    <li>Infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party;</li>
                    <li>Is fraudulent, false, misleading, or deceptive;</li>
                    <li>Is defamatory, obscene, pornographic, vulgar, or offensive;</li>
                    <li>Promotes violence, discrimination, or hatred;</li>
                    <li>Violates the law or any third-party rights; or</li>
                    <li>Is otherwise inappropriate as determined by us in our sole discretion.</li>
                </ul>

                <h2>3. Modification and Termination of Service</h2>
                <p>We reserve the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice at any time. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service.</p>

                <h2>4. Disclaimer of Warranties</h2>
                <p>THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY FOR INFORMATION, SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS PROVIDED THROUGH OR IN CONNECTION WITH THE SERVICE, INCLUDING WITHOUT LIMITATION THE SOFTWARE LICENSED TO YOU AND THE RESULTS OBTAINED THROUGH THE SERVICE. SPECIFICALLY, WE DISCLAIM ANY AND ALL WARRANTIES, INCLUDING WITHOUT LIMITATION: 1) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, OR CONTENT OF INFORMATION, AND 2) ANY WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                <p>THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.</p>

                <h2>5. Limitation of Liability</h2>
                <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE.</p>

                <h2>6. Indemnification</h2>
                <p>You agree to indemnify, defend and hold harmless Xlearner, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, agents, successors, assigns, content providers, and other representatives from and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from (i) your use of the Service, (ii) User Content submitted by you, (iii) your violation of these Terms, or (iv) your violation of any rights of another.</p>

                <h2>7. General Terms</h2>
                <p>These Terms, together with the Privacy Policy, constitute the entire agreement between you and us with respect to the use of the Service. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>

                <h2>8. Governing Law</h2>
                <p>These Terms and your use of the Service are governed under the laws of the jurisdiction in which the Service is hosted, without regard to its conflict of laws provisions.</p>

                <h2>9. Dispute Resolution</h2>
                <p>Any dispute arising from or relating to these Terms or the Service shall be resolved through binding arbitration, rather than in court.</p>

                <h2>10. Contact Information</h2>
                <p>If you have any questions or concerns regarding these Terms, please contact us at admin@xlearner.com.</p>
                <p>Last updated: 2023/02/01 </p>

            </div>
        );
    }
}

export default TermofUse;

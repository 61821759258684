import { createContext, useState, useEffect } from 'react';
import axios from "axios";
import appConfig from "./config.json";

const UserContext = createContext();

const UserProvider = ({ children }) => {

  const initialLoggedInUser = sessionStorage.getItem('loggedInUser') || '';
  const initialLoginUserId = sessionStorage.getItem('loginUserId') || '';
  const initialLoginUserEmail = sessionStorage.getItem('loginUserEmail') || '';
  const initialLoginUserProfileImg = sessionStorage.getItem('loginUserProfileImg') || '';
  const initialProfileHasNewNotification = false; // Assuming default is false

  const [loggedInUser, setLoggedInUser] = useState(initialLoggedInUser);
  const [loginUserId, setLoginUserId] = useState(initialLoginUserId);
  const [loginUserEmail, setLoginUserEmail] = useState(initialLoginUserEmail);
  const [loginUserProfileImg, setLoginUserProfileImg] = useState(initialLoginUserProfileImg);
  const [profileHasNewNotification, setProfileHasNewNotification] = useState(initialProfileHasNewNotification);

  useEffect(() => {
    sessionStorage.setItem('loggedInUser', loggedInUser);
    sessionStorage.setItem('loginUserId', loginUserId);
    sessionStorage.setItem('loginUserEmail', loginUserEmail);
    sessionStorage.setItem('loginUserProfileImg', loginUserProfileImg);
  }, [loggedInUser, loginUserId, loginUserEmail, loginUserProfileImg]);

  // Function to fetch notification status
  const checkUserNotification = async () => {
    // Replace with your actual API call to check for notifications
    const hasNotification = await fetchHasUnclearedNotification(loginUserId);
    setProfileHasNewNotification(hasNotification);
  };

  useEffect(() => {
    if (loginUserId) {
      checkUserNotification();
    }
  }, [loginUserId]);

  return (
    <UserContext.Provider value={{ 
      loggedInUser, 
      setLoggedInUser, 
      loginUserId, 
      setLoginUserId, 
      loginUserEmail, 
      setLoginUserEmail, 
      loginUserProfileImg, 
      setLoginUserProfileImg,
      profileHasNewNotification,
      setProfileHasNewNotification, 
    }}>
        {children}
    </UserContext.Provider>
  );
};

const fetchHasUnclearedNotification = async (uId) => {
  try {
    const response = await axios.get(`${appConfig.SERVER}/notifications/${uId}/hasUnclearedNotification`);
    return response.data.hasUnclearedNotification;

  } catch (error) {
    console.error('Error fetching uncleared notifications:', error);
    return false;
  }
};

export { UserContext, UserProvider };
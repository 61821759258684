import React, { useState, useEffect, useContext } from 'react';
import { EditorState, Modifier, convertToRaw, convertFromRaw  } from 'draft-js';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import { UserContext } from "./UserProvider";
import { timeGap } from './utils';
import { stateToHTML } from 'draft-js-export-html'
import { ContextMenu, MenuItem, showMenu } from 'react-contextmenu';
import ConfirmModal from './ConfirmModal';
import { BiEdit, BiTrash, BiMessageRoundedError, BiChevronDown, BiChevronUp, BiLike, BiDislike, BiCool, BiCaretRight } from "react-icons/bi";

import "./styles/comment.css";

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

const CommentPanel = ({ recordId, commtHost }) => {

    const {loggedInUser, loginUserId, loginUserProfileImg} = useContext(UserContext);
    const [rootComments, setRootComments] = useState([]);
    const [validateMessage, setValidateMessage] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [emojiPicker, setEmojiPicker] = useState(false);
    const [isEditorVisible, setIsEditorVisible] = useState(false);
    
    //for reply and contextMenu 
    //selectedCommentId is the comment that the editor div will replace its infoDiv
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [selectedCommentRootId, setSelectedCommentRootId] = useState(null);
    const [hiddenInfoDivId, setHiddenInfoDivId] = useState(null);

    //for react-modal box
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
    const confirmDeleteCommentTxt = "Are you sure you want to delete this comment?";

    //for editing
    const [isEditingComment, setIsEditingComment] = useState(false);
    //for deleting
    const [deleteCommentId, setDeleteCommentId] = useState(null);

    //for reply
    const [isReplyMode, setIsReplyMode] = useState(false);
    const [replyToRootCommentId, setReplyToRootCommentId] = useState(null);
    const [replyToUserId, setReplyToUserId] = useState(null);

    //for sort comment and reply
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('createdOn');


    const getBaseURL = (host) => {
      if (host === 'record') {
          return 'commt'; // Replace with your actual URL A
      } else if (host === 'request') {
          return 'requestcommt'; // Replace with your actual URL B
      } else {
          throw new Error(`Invalid commtHost value: ${host}`);
      }
    };

    /**
     * toggle comment sort order
     */
    const toggleSortOrder = () => {
      setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    /**  
     * toggle the emj picker
    */
    const onClickEmojiPicker = () => {
      setEmojiPicker(!emojiPicker);
    };

    /**
     * handle when an emoji is selected from the picker
     */
    const onEditorEmojiSelect = (emoji) => {
      const selection = editorState.getSelection();
      let contentState = editorState.getCurrentContent();
      const emojiStr = emoji.native;
      contentState = Modifier.replaceText( contentState, selection, emojiStr );
      setEditorState( EditorState.push(editorState, contentState, 'insert-characters') );
      setEmojiPicker(false);
    };

    /**
     * the function to handle click 'submit' button event, 
     * submit a new comment from the editor window
     * handle validation, if it's empty, then do not post
     */ 
    const onSubmitComment = async () => {
      const contentState = editorState.getCurrentContent();
      const commtText = contentState.getPlainText();
      if (commtText.trim() === "") { //validate the input
        setValidateMessage("Please enter your comment.");
        setTimeout(()=>{ setValidateMessage("");}, 5001);
        return;
      }
      const rawContentState = convertToRaw(contentState);
      const contentJSONString = JSON.stringify(rawContentState);
      if(isEditingComment) { //edit a current comment
        const commtToUpdate = { text: contentJSONString };
        const data = await updateComment(commtToUpdate, selectedCommentId);
        setIsEditingComment(false);
        setSelectedCommentId(null);
        setHiddenInfoDivId(null);
      } else { //create a new comment (either root comment or reply comment)
        const commtToAdd = { userId: loginUserId,  text: contentJSONString, };
        if(isReplyMode) { //if reply, attached two more properties
          commtToAdd.replyToCommentId = replyToRootCommentId;
          commtToAdd.replyToUserId = replyToUserId;
        }
        const data = await createNewComment(commtToAdd); 
        setEditorState(EditorState.createEmpty());
        setIsEditorVisible(false);
        if(isReplyMode) { //if reply comment added, then fetch the replies
          await fetchRootCommtReplies(replyToRootCommentId);
          setIsReplyMode(false);
          setReplyToRootCommentId(null);
          setReplyToUserId(null);
        } else { //if root comment added, reload the root commts
          loadRootComments();
        }
      }
      setEditorState(EditorState.createEmpty());
      setIsEditorVisible(false);
      setSelectedCommentId(null);
      setHiddenInfoDivId(null);
    };

    /**
     * create a new comment
     */
    const createNewComment = async (commtToAdd) => {
      try {
          const baseURL = getBaseURL(commtHost);
          const response = await fetch(`/${baseURL}/${recordId}/add`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(commtToAdd)
          });
          const data = await response.json();
          return data;
      } catch (error) {
          console.log("error occurred: ", error);
      }
    }

    /** 
     *  update a comment
     *  it can be either root comment or reply comment
     */
    const updateComment = async (commtToUpdate, commtId) => {
        try {
            const baseURL = getBaseURL(commtHost);
            const response = await fetch(`/${baseURL}/${recordId}/${commtId}/update`, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, 
                body: JSON.stringify(commtToUpdate)
            });
            if(!response.ok) {
              console.error("Error updating comment, please check server error log");
              return;
            }
            const data = await response.json();
            if (!data.lastEditOn) {
              console.error("Error updating comment, please check server error log");
              return;
            }
            if (!selectedCommentRootId) { //if it is a root comment
              const updatedComments = rootComments.map(commt => commt.commentId === commtId ? {
                ...commt,
                text: commtToUpdate.text,
                lastEditOn: data.lastEditOn
              } : commt);
              setRootComments(updatedComments);
            } else { //if it is a reply comment
              const rootCommtIndex = rootComments.findIndex(commt => commt.commentId === selectedCommentRootId);
              const rootComment = rootComments[rootCommtIndex];
              // If not found, just return
              if (rootCommtIndex === -1) {
                console.error("Error updating comment, we dont find the root comment with ID:" + selectedCommentRootId);
                return;
              }
              // update the replies for the root
              const updatedReplies = rootComment.replies.map(reply => {
                if (reply.commentId === commtId) {
                  return { ...reply, text: commtToUpdate.text, lastEditOn: data.lastEditOn };
                }
                return reply;
              });
              // construct the root
              const updatedRootComment = { ...rootComment, replies: updatedReplies };
              const newRootComments = [...rootComments];
              newRootComments[rootCommtIndex] = updatedRootComment;
              // update the state
              setRootComments(newRootComments);
            }
        } catch (error) {
            console.error("Error updating comment:", error);
        }
    };

    /** 
     * delete a comment by commentId
    */
    const deleteComment = async (commtId) => {
      try {
          const baseURL = getBaseURL(commtHost);
          const response = await fetch(`/${baseURL}/${recordId}/${commtId}/delete`, { method: 'DELETE' });
          if (response.ok) {
            const data = await response.json();
            if (data.isRoot) {
              setRootComments(prevComments => prevComments.filter(commt => commt.commentId !== commtId));
            } else {
              setRootComments(prevComments => {
                const updatedComments = [...prevComments];
                const rootComment = updatedComments.find(commt => commt.commentId === data.rootCommentId);
                if (rootComment) {
                    rootComment.replies = rootComment.replies.filter(reply => reply.commentId !== commtId);
                    rootComment.numOfReplies = rootComment.replies ? rootComment.replies.length : 0;
                }
                return updatedComments;
              });
            }
          } else {
            const data = await response.json();
            console.error("Error deleting comment:", data.message); 
          }
      } catch (error) {
          console.error("Error:", error);
      }
    };

    /**
     * when click the validation message for editor, disappear it
     */
    const hideValidateMsg = () => {
      setValidateMessage("");
    };

    /**
     * display comment editor
     */
    const displayCommentEditor = () => {
      setIsEditorVisible(true);
    };

    /**
     * content editor reset
     * when the cancel button is clicked at the editor, it will reset the editor to the top
     */
    const hideCommentEditor = () => {
      setEditorState(EditorState.createEmpty());
      setSelectedCommentId(null);
      setIsEditorVisible(false);
      setHiddenInfoDivId(null);
    };

    /**
     * fetch all the root comments for a given record
     */
    const loadRootComments = async () => {
      try {
        if(!recordId) { return;}
        const baseURL = getBaseURL(commtHost);
        const response = await fetch(`/${baseURL}/${recordId}/root`);
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        const rootCommtsItems = await response.json();
        const updatedRootCommts = rootCommtsItems.map(rootCommt => {
          rootCommt.isRepliesOpen = false;
          return rootCommt;
        });
        setRootComments(updatedRootCommts);
      } catch (error) {
        console.error('Failed to load comments:', error);
      }
    };

    /**
     * this method will automatially set the root comment replies open
     */
    const fetchRootCommtReplies = async (rootCommtId) => {
      try {
        const index = rootComments.findIndex(commt=> commt.commentId === rootCommtId);
        if (index !== -1) {
            const updateRootComments = [...rootComments];
            const baseURL = getBaseURL(commtHost);
            const response = await fetch(`/${baseURL}/${recordId}/${rootCommtId}/replies`);
            if (!response.ok) {
              throw new Error(`HTTP error ${response.status}`);
            }
            const replyCommtsItems = await response.json();
            updateRootComments[index].replies = replyCommtsItems;
            updateRootComments[index].isRepliesOpen = true;
            updateRootComments[index].numOfReplies = replyCommtsItems.length;
            setRootComments(updateRootComments);
        }
      } catch (error) {
        console.error('Failed to fecth replies for comment', error);
      }
    };

    /**
     * when reply button click, display the editor right below the comment item
     * ### still need to work on reply to a reply
     */
    const onReplyBtnClick = (e, commt, isRoot) => {
      setIsReplyMode(true);
      setReplyToUserId(commt.userId);
      //check if it's replying to a root
      if(isRoot) {
        setReplyToRootCommentId(commt.commentId);
      } else { //not root then
        setReplyToRootCommentId(commt.replyToId);
      }
      setSelectedCommentId(commt.commentId);
    };

    /**
     * handle upvote and downvote buttons click event
     */
    const onVoteBtnClick = (e, commtId, action) => {
      // post a upvote to the server ->loginUserId
      console.log(`commtId: ${commtId}`);
      voteToComment(commtId, action);
    };

    /**
     * handles the event when click view replies on a root comment 
     * fetch all replies of it
     */
    const onViewRepliesBtnClick = (e, rootCommt) => {
      if(rootCommt.isRepliesOpen) { // if its already open, set isRepliesOpen to false
        const updatedRootCommts = rootComments.map(commt => {
          if (commt.commentId === rootCommt.commentId) { commt.isRepliesOpen = false; }
          return commt;
        });
        setRootComments(updatedRootCommts);
      } else { //if its collpase, fetch new replie
        fetchRootCommtReplies(rootCommt.commentId);
      }
    };
    
    /**
     * handle click to context menu of the comment
     */
    const onContextMenuClick = (e, commtId) => {
      showMenu({
        position: { x: e.clientX, y: e.clientY },
        id: 'contextmenu-' + commtId
      });
    };

    /**
     * handle click to 'Edit' menuItem in context menu of the comment
     */
    const onContextMenuEditClick = (commt, isRoot) => {
      const commtId = commt.commentId;
      setIsEditingComment(true);
      setHiddenInfoDivId(commtId);
      let commentToEdit;
      if(isRoot) { //root
        setSelectedCommentRootId(null); 
        commentToEdit = rootComments.find(comment => comment.commentId === commtId);
      } else { //if its a reply comment under root
        const rootCommt =  rootComments.find(comment => comment.commentId === commt.replyToId);
        if(!rootCommt) {
          console.log("Error: cannot locate this comment's root with id:" + commt.replyToId);
          setHiddenInfoDivId(null);
          return;
        } else {
          commentToEdit = rootCommt.replies.find(comment => comment.commentId === commtId);
          setSelectedCommentRootId(rootCommt.commentId);
        }
      }
      if (!commentToEdit) {
        console.log("Error: cannot find comment with id:" + commtId);
        setHiddenInfoDivId(null);
        return;
      }
      // fill the editor with content to edit
      const rawCommentContent = commentToEdit.text;
      const contentStateToEdit = convertFromRaw(JSON.parse(rawCommentContent));
      const newEditorState = EditorState.createWithContent(contentStateToEdit);
      setEditorState(newEditorState);
      setSelectedCommentId(commtId);
    };

    /**
     * handle comment context menu delete click
     */
    const onContextMenuDeleteClick = (commt) => {
      setDeleteCommentId(commt.commentId);
      setIsConfirmBoxOpen(true);
    };

    /**
     * handle when confirm comment deletion on modal box
     */ 
    const onDeletionModalBoxConfirm = async () => {
      await deleteComment(deleteCommentId);
      setDeleteCommentId(null);
      setIsConfirmBoxOpen(false);
    };

    /**
     * handle when cancel comment deletion on modal box
     */ 
    const onDeletionModalBoxCancel = () => {
      setDeleteCommentId(null);
      setIsConfirmBoxOpen(false);
    };
  
    /**
     * handle comment context menuItem 'Report' click event
     */ 
    const onContextMenuReportClick = (commt) => {
      // Handle the report click here
      console.log(`Reporting comment with id: ${data.id}`);
    };

    /**
     * display the right comment notice text
     */ 
    const getCommentTextByLength = (len) => {
      if(len===0) {
        return "Be the first to comment on this post";
      } else if(len === 1) {
        return "1 comment";
      } else {
        return len + " comments";
      }
    };

    /**
     * display the righte view replies text
     */
    const getRepliesTextByLength = (len) => {
      if(len===0) {
        return "";
      } else if(len === 1) {
        return " reply ";
      } else {
        return " replies ";
      }
    };

    /**
     * display the right replies button
     */
    const getViewRepliesBtnText = (commt) => {
      if(commt.isRepliesOpen){
        return <>{getRepliesTextByLength(commt.numOfReplies)} <BiChevronUp/></>
      } else {
        return <>{getRepliesTextByLength(commt.numOfReplies)} <BiChevronDown/></>
      }
    };

    /**
    * utility function, convert the comment raw item to html
    */ 
    const convertToHtml = (rawContent) => {
      var contentState = convertFromRaw(JSON.parse(rawContent));
      var editorState = EditorState.createWithContent(contentState);
      var htmlContent = stateToHTML(editorState.getCurrentContent());
      return htmlContent;
    };

    /**
     * post vote to backend (upvote and downvote)
     */
    const voteToComment = async (commtId, action) => {
      const userId = loginUserId;
      const baseURL = getBaseURL(commtHost);
      const response = await fetch(`/${baseURL}/vote`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ recordId, commtId, userId, action })  // Add the action parameter
      });

      const data = await response.json();
      if (response.ok) {
        const updatedCounts = { numOfUpvotes: data.numOfUpvotes, numOfDownvotes: data.numOfDownvotes };
        setRootComments(prevComments => updateCommentVoteCounts(prevComments, commtId, updatedCounts));
      }
    };

    /**
     * update the comment vote counts for its state so it displays next to the up/down vote button
     */
    const updateCommentVoteCounts = (existingCommts, targetCommtId, updatedCounts) => {
      return existingCommts.map(comment => {
          if (comment.commentId === targetCommtId) {
              return {...comment, numOfUpvotes: updatedCounts.numOfUpvotes, numOfDownvotes: updatedCounts.numOfDownvotes};
          }
          return comment;
      });
    }

    /**
     * for sort comments
     */
    const sortComments = (a, b, sortBy, sortOrder) => {
      return sortOrder === 'desc' 
          ? b[sortBy] - a[sortBy] 
          : a[sortBy] - b[sortBy];
    };

    /**
     * initalizing the component
     */
    useEffect(() => {
      loadRootComments();
    }, [recordId]);

    /**
     * render the editor for comment item
     * it can be reply editor or root comment editor
     */
    const RenderEditorDiv = (isReplyEditor) => {
      return <div className={isReplyEditor ? "comment-editor-section comment-editor-reply" : "comment-editor-section" }>
                <div className="comment-editor-head">
                  <img className="auth-init-round-comment" src={loginUserProfileImg} />
                  <div className="comment-item-authfullname">{loggedInUser}</div>
                </div>
                <Editor className="editor-area" editorState={editorState} onChange={setEditorState} plugins={plugins} />
                <div className="editor-toolbar"><Toolbar></Toolbar></div>
                <button className="comment-btn-emj" onClick={onClickEmojiPicker}><BiCool/></button>
                {emojiPicker ? (<Picker className="emj-picker" data={data} onEmojiSelect={onEditorEmojiSelect} />) : null}
                <button className="comment-btn" onClick={onSubmitComment}>{hiddenInfoDivId ? "Save": "Post"}</button>
                <button className="comment-btn comment-btn-cancel" onClick={hideCommentEditor}>Cancel</button>
                <div className="validate-comment-msg" onClick={hideValidateMsg}>{validateMessage}</div>
              </div>
    };

    /**
     * render the comment item div html 
     * it can be a root comment or a reply
     */
    const RenderCommentItemDiv = (item, isRoot) => {
      return <div className={isRoot? "comment-item-content" : "comment-item-content-reply"} data-cid={item.commentId}>
                <img className="auth-init-round-comment" src={item.userDetails.profilePicture} />
                <div className="comment-item-authfullname">{item.userDetails.username}</div>
                {isRoot? null : <div class="comment-item-replyTo"><BiCaretRight/> {item.replyToUserDetails.username}</div>}
                <div className="comment-item-createdon">{timeGap(Date.now(), item.lastEditOn)}</div>
                {item.userId===loginUserId && <button className="comment-item-contextmenu-btn" onClick={(e)=> onContextMenuClick(e, item.commentId)}>...</button>}
                <ContextMenu className='comment-contextmenu-wrapper' id={`contextmenu-${item.commentId}`}>
                  {item.userId===loginUserId && <MenuItem className='comment-contextmenu-item' onClick={()=>onContextMenuEditClick(item, isRoot)}><BiEdit/><span>Edit</span></MenuItem>}
                  {item.userId===loginUserId && <MenuItem className='comment-contextmenu-item' onClick={()=>onContextMenuDeleteClick(item)}><BiTrash/><span>Delete</span></MenuItem>}
                  {/*<MenuItem className='comment-contextmenu-item' onClick={()=>onContextMenuReportClick(item)}><BiMessageRoundedError/><span>Report</span></MenuItem>*/}
                </ContextMenu>
                <div className="comment-item-body" dangerouslySetInnerHTML={{ __html: convertToHtml(item.text)}} />  
                <div className="comment-item-actions">
                  <button className="comment-action-btn" onClick={(e)=> onVoteBtnClick(e, item.commentId, "upvote")}><BiLike/></button>
                  <div className="comment-upvotes">{item.numOfUpvotes}</div>
                  <button className="comment-action-btn" onClick={(e)=> onVoteBtnClick(e, item.commentId, "downvote")}><BiDislike/></button>
                  <div className="comment-downvotes">{item.numOfDownvotes}</div>
                  <button className="comment-reply-btn" onClick={(e)=> onReplyBtnClick(e, item, isRoot)}>Reply</button>
                  {item.numOfReplies > 0 && (
                  <button className='comment-viewreplies-btn' onClick={(e)=> onViewRepliesBtnClick(e, item)}>{item.numOfReplies} {getViewRepliesBtnText(item)}</button>)
                  }
                </div>
            </div>
    };    

    return (
    <>
        { /* comment hearder - add comment inputbox - comment count */ }
        <div class="square-list-comment-row">
            {!isEditorVisible && (
              <div className="comment-topbar">
                <div className="comment-number-div">{getCommentTextByLength(rootComments.length)}</div>
                <button className="comment-btn-open" onClick={displayCommentEditor}>Comment</button>
              </div>
            )}
            {isEditorVisible && ((selectedCommentId === null) && RenderEditorDiv(false))}
        </div>
        {/* comment list - iterate thru comments */}
        <ul className="learner-res-comment-list list-group list-group-flush">
        {rootComments.slice().sort((a, b) => sortComments(a, b, sortBy, sortOrder)).map((item) => {
          if (item.text != "") {
            return ( /* each comment item - editor */
            <li className="list-group-item learner-res-comment-item" >
            {hiddenInfoDivId !== item.commentId && (RenderCommentItemDiv(item, true))}
            {selectedCommentId === item.commentId && RenderEditorDiv(false)}
            {item.replies && item.isRepliesOpen && item.replies.map(reply => (
            <> {/* each reply - and editor */}
            {hiddenInfoDivId !== reply.commentId && (RenderCommentItemDiv(reply, false))}
            {selectedCommentId === reply.commentId && RenderEditorDiv(true)}
            </>))}
            </li>);
          }})}
        </ul>
        {/* modal box for confirm delete */}
        {isConfirmBoxOpen && <ConfirmModal onConfirm={onDeletionModalBoxConfirm} onCancel={onDeletionModalBoxCancel} contentText={confirmDeleteCommentTxt}/>}
    </>
    );    
  };
  
  export default CommentPanel;
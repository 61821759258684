import React from 'react'
import "../styles/content-page.css";


class SiteMap extends React.Component {
  
    render() {

        return (
            <div class="page-wrapper-div sitemap-div">
                <h1>Site Map</h1>

                <h2>Main Pages</h2>
                <ul>
                    <li><a href="/about-us/our-mission">Home</a></li>
                    <li><a href="/about-us/our-team">Resources Browsing</a></li>
                </ul>

                <h2>About Us</h2>
                <ul>
                    <li><a href="/about-us/our-mission">Our Mission</a></li>
                    <li><a href="/about-us/our-team">Our Team</a></li>
                    <li><a href="/about-us/contact-us">Contact Us</a></li>
                </ul>

                <h2>FAQ</h2>
                <ul>
                    <li><a href="/about-us/our-mission">A-Z</a></li>
                    <li><a href="/about-us/our-team">Q & A</a></li>
                </ul>
            </div>
        );
    }
}

export default SiteMap;

import React, { useEffect, useState, useRef, useContext  } from 'react';
import {  RiCloseCircleFill, RiHeartsLine, RiMagicLine, RiFireLine, RiDeleteBin5Line, RiFileEditLine } from "react-icons/ri";
import { BiCool } from "react-icons/bi";

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Editor from 'draft-js-plugins-editor';
import { EditorState, Modifier, convertToRaw, ContentState, convertFromRaw  } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html'
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js/dist/Draft.css';

import CommentPanel from './CommentPanel';
import ConfirmModal from './ConfirmModal';
import { UserContext } from "./UserProvider";

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];



// this is a class component to display the item details
// shows when the square item is clicked
const RequestListModalBox = () => {

    const [requestItems, setRequestItems] = useState([]);
    const [selectedRequestItem, setSelectedRequestItem] = useState(null);
    const [page, setPage] = useState(1);
    const [totalNumReqs, setTotalNumReqs] = useState(0); 
    const [fetchSize, setFetchSize] = useState(9); 
    const [deleteRequestItem, setDeleteRequestItem] = useState(null);

    const maxCharNum = 170;

    //for react-modal box
    const [isConfirmDeleteBoxOpen, setIsConfirmDeleteBoxOpen] = useState(false);
    const confirmDeleteCommentTxt = "Are you sure you want to delete this request?";

    const {loggedInUser, loginUserId, loginUserProfileImg} = useContext(UserContext);

    const closeBtnRef = useRef(null); 
    const totalPages = Math.ceil(totalNumReqs / fetchSize);

    //for editing request
    const titlePlaceholder = "What experience would you like to request from others?";
    const notePlaceholder = "";
    const [isEditingRequest, setIsEditingRequest] = useState(false);
    const [editRequestValidateMessage, setEditRequestValidateMessage] = useState("");
    const initialContent = ContentState.createFromText(notePlaceholder);
    const [emojiPicker, setEmojiPicker] = useState(false);

    const [requestItemToEdit, setRequestItemToEdit] = useState(null);
    const [requestItemToEditTitle, setRequestItemToEditTitle] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContent));

    const onEditorEmojiSelect = (emoji) => {
      const selection = editorState.getSelection();
      let contentState = editorState.getCurrentContent();
      const emojiStr = emoji.native;
      contentState = Modifier.replaceText( contentState, selection, emojiStr );
      setEditorState( EditorState.push(editorState, contentState, 'insert-characters') );
      setEmojiPicker(false);
    };

    const onClickEmojiPicker = () => {
      setEmojiPicker(!emojiPicker);
    };

    /**
     * load all the request from server
     */
    const loadRequestItems = async () => {
      try {
        const response = await fetch(`/requests/all?page=${page}&size=${fetchSize}`);
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        const reqItemsResponseData = await response.json();
        setRequestItems(reqItemsResponseData.data);
        setTotalNumReqs(reqItemsResponseData.total);
      } catch (error) {
        console.error('Failed to load RequestItems:', error);
      }
    };

    const deleteSelectRequest = async () => {
      try {
        const deleteId = deleteRequestItem._id;
        const response = await fetch(`/requests/${deleteId}/delete`, {
          method: 'DELETE',
        });

        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }

      } catch (error) {
        console.error('Failed to delete RequestItems:', error);
      }
    };

    /**
    * utility function, convert the comment raw item to html
    */ 
    const convertToHtml = (rawContent) => {
      var contentState = convertFromRaw(JSON.parse(rawContent));
      var editorState = EditorState.createWithContent(contentState);
      var htmlContent = stateToHTML(editorState.getCurrentContent());
      return htmlContent;
    };

    /**
     * delete button clicked
     */
    const onDeleteRequestBtnClick = (e) => {
      const dataRid = e.currentTarget.dataset.rid;
      const foundItem = requestItems.find(item => item._id === dataRid);
      if (foundItem) {
        setDeleteRequestItem(foundItem);
        setIsConfirmDeleteBoxOpen(true);
      } else {
          console.error(`No item found with ID: ${dataRid}`);
          setDeleteRequestItem(null);
      }
    };

    /**
     * when confirm deletion
     */
    const onDeletionModalBoxConfirm = async () => {
      await deleteSelectRequest();
      setIsConfirmDeleteBoxOpen(false);
      setDeleteRequestItem(null);
      loadRequestItems(); // reload the items
    };

    /**
     * when cancel deletion
     */
    const onDeletionModalBoxCancel = () => {
      setIsConfirmDeleteBoxOpen(false);
      setDeleteRequestItem(null);
    };

    /**
     * when select a request item from the list
     */
    const onSelectRequestItem = (e) => {
      //console.log("onSelectRequestItem is fired.");
      const dataRid = e.currentTarget.dataset.rid;
      //console.log(dataRid);
      const foundItem = requestItems.find(item => item._id === dataRid);
      if (foundItem) {
          setSelectedRequestItem(foundItem);
      } else {
          console.error(`No item found with ID: ${dataRid}`);
          setSelectedRequestItem(null);
      }
    };

    const onViewAllQuestions = () => {
      setSelectedRequestItem(null);
    };

    const onClickEditRequestItem = (reqItem) => {
      console.log(reqItem);

      setRequestItemToEdit(reqItem);
      setRequestItemToEditTitle(reqItem.question_content);
      const rawCommentContent = reqItem.note;
      const contentStateToEdit = convertFromRaw(JSON.parse(rawCommentContent));
      const newEditorState = EditorState.createWithContent(contentStateToEdit);
      setEditorState(newEditorState);

      setIsEditingRequest(true);
    };



    const onRequestItemEditTitleChange = (e) => {
      setRequestItemToEditTitle(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const onEditingRequestCancelBtnClick = () => {
      setIsEditingRequest(false);
      //REVIEW: need some other cleanups
    };

    const hideEditRequestValidateMessage = () => {
      setEditRequestValidateMessage("");
    };

    const onEditingRequestSaveBtnClick = () => {
      if (requestItemToEditTitle === "") {
        //show validation msg, then return
        setEditRequestValidateMessage("The question cannot be empty.");
        setTimeout(()=>{ setEditRequestValidateMessage("");}, 5001);
        return;
      }

      console.log("saved");
      //REVIEW: add logic here
      

      setIsEditingRequest(false);

    };

    



    const onCloseBtnClick = () => {
      setSelectedRequestItem(null);
    };

    useEffect(() => {
      let didCancel = false;
      if (!didCancel) {
        loadRequestItems();
      }
      return () => {
        didCancel = true; // This is to avoid any async issues if the component gets unmounted before an async operation completes.
      };
    }, [page, fetchSize]);

    return (
        <>
        <div class="modal fade" id="requestListModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content square-detail-modal-content">
                {/* the header - user image - title */}
                <div class="modal-header new-request-header">
                    <button ref={closeBtnRef} type="button" class="btn-close square-detail-close-btn new-request-close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseBtnClick}><RiCloseCircleFill/></button>
                </div>
                <div class="modal-body square-list-steps-body">
                  {/* request list */}
                  { !selectedRequestItem && !isEditingRequest &&
                  <>
                    {/*<div className='request-btn-div'>
                      <button className='request-list-sort-btn'><RiFireLine /> Hot</button>
                      <button className='request-list-sort-btn'><RiMagicLine /> New</button>
                      <button className='request-list-sort-btn'><RiHeartsLine/> Most Liked</button>
                    </div>*/}
                    <ul class="list-group list-group-flush request-lst-ul" >
                    {requestItems.map((reqItem, index) => {
                      return (
                        <li class="list-group-item">
                          <div className="reqest-item-div" data-rid={reqItem._id} onClick={onSelectRequestItem}>{reqItem.question_content}</div>
                          {(reqItem.authorId === loginUserId) && 
                          <div className='reqest-item-actions'>
                            <button className='reqest-item-btn' onClick={(e)=>onClickEditRequestItem(reqItem)} ><RiFileEditLine /> Edit</button>
                            <button className='reqest-item-btn' data-rid={reqItem._id} onClick={(e)=>onDeleteRequestBtnClick(e)} ><RiDeleteBin5Line /> Delete</button>
                          </div>}
                        </li>
                      );
                      })}
                    </ul>
                    <div className="pagination-function-div">
                      <button className="page-btn" onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))} disabled={page === 1}>Previous</button> &nbsp;
                      <div className="pagination-pages-div">Page {page} of {totalPages}</div> &nbsp;
                      <button className="page-btn" onClick={() => setPage(prevPage => prevPage + 1)} disabled={page === totalPages}>Next</button>
                    </div>
                  </> }
                  {/* show request detail and commets*/}
                  { selectedRequestItem && 
                    <div className='request-detail-div'>
                      <button className='view-all-question-btn' onClick={onViewAllQuestions}>Back To All Questions</button>
                      <div className='request-detail-question'>{selectedRequestItem.question_content}</div> 
                      <div className="request-detail-note" dangerouslySetInnerHTML={{ __html: convertToHtml(selectedRequestItem.note)}} />
                      {loginUserId ? ( <CommentPanel recordId={selectedRequestItem._id} commtHost={"request"} ></CommentPanel> ) : ( <div className='login-see-commts-div'>Please login to see comments.</div> )}
                    </div>}

                  { isEditingRequest && 
                    <div className='request-detail-div'>
                      <textarea class="form-control request-title-textarea" name="question-title"  as="textarea" rows='1' value={requestItemToEditTitle}
                                onChange={onRequestItemEditTitleChange} placeholder={titlePlaceholder} maxLength={maxCharNum}  />
                      {<div className='remaining-chars-div'> {maxCharNum} characters remaining </div>}
                      <Editor className="editor-area" editorState={editorState} onChange={setEditorState} plugins={plugins} />
                      <div className="editor-toolbar"><Toolbar></Toolbar></div>
                      <button className="comment-btn-emj" onClick={()=>onClickEmojiPicker()}><BiCool/></button>
                      {emojiPicker ? (<Picker className="emj-picker" data={data} onEmojiSelect={onEditorEmojiSelect} />) : null}
                      <button className="comment-btn" onClick={()=>onEditingRequestCancelBtnClick()}>Cancel</button>
                      <button className="comment-btn" onClick={()=>onEditingRequestSaveBtnClick()} >Save</button>
                      <div className="validate-comment-msg" onClick={()=>hideEditRequestValidateMessage()}>{editRequestValidateMessage}</div>
                      </div>
                    }
                </div>
            </div>
            </div>
        </div>
        {/* modal box for confirm delete */}
        {isConfirmDeleteBoxOpen && <ConfirmModal onConfirm={onDeletionModalBoxConfirm} onCancel={onDeletionModalBoxCancel} contentText={confirmDeleteCommentTxt}/>}
        </>
    );
  };
  
  export default RequestListModalBox;

  {/**<div className="request-item-body" dangerouslySetInnerHTML={{ __html: convertToHtml(selectedRequestItem.note)}} />   */}
  {/**<div className='request-detail-note'>{selectedRequestItem.note}</div> */}
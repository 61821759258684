/* eslint-disable no-lone-blocks */
/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {UserProvider} from "./UserProvider";

import SquareDetails from "./SquareDetails";
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";
import HomePage from "./HomePage";
import SiteMap from "./pages/Sitemap";
import AboutUs from "./pages/AboutUs";
import TermofUse from "./pages/TermofUse";
import Privacy from "./pages/Privacy";
import LearningRecordPage from "./LearningRecordPage";
import ProfilePage from "./ProfilePage";

import "./styles/app.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap/dist/css/bootstrap.min.css";

//import "https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.js";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      squaresInfo: [],
    }
  }

  render() {

    return (
      <UserProvider>
        <TopMenu></TopMenu>
          <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
            <Route exact path="/home" element={<HomePage />} />
            <Route exact path="/browsing" element={<LearningRecordPage />} />
            <Route exact path="/profile" element={<ProfilePage />} />
            <Route exact path="/browsing/:squareid" element={ <SquareDetails square_details={this.state.squaresInfo} />} />
            <Route exact path="/privacy" element={ <Privacy /> } />
            <Route exact path="/sitemap" element={ <SiteMap /> } />
            <Route exact path="/aboutus" element={ <AboutUs /> } />
            <Route exact path="/termofuse" element={ <TermofUse /> } />
          </Routes>
        <Footer></Footer>
      </UserProvider>
    );
  }
}

export default App;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/contact-us.css'; // Import the CSS file

export default function ContactUs({ onClose }) {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if user is logged in
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get('/protected'); // Assumes the route returns user data if logged in
        if (response.data && response.data.userEmail) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        setIsLoggedIn(false); // User is not logged in
      }
    };
    checkLoginStatus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isLoggedIn) {
      setError('Please log in to send a message.');
      return;
    }

    // Trim the message to remove any extra spaces
    if (message.trim() === '') {
      setError('Message cannot be empty.');
      return;
    }
  
    try {
      const response = await axios.post('/api/formSubmission4529', { message });
      if (response.data.success) {
        setSuccess('Message sent successfully!');
        setMessage('');
        setError('');  // Clear the error message if any
      } else {
        setError(response.data.error || 'Failed to send message.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };
  
  return (
    <div className="contact-us-modal">
      <div className="contact-us-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        
        {isLoggedIn ? (
          <form onSubmit={handleSubmit}>
            <textarea
              className='contact-us-text'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Anything you want to share with us?"
              required
            />
            <button type="submit" className="submit-button">Share with Potential</button>
          </form>
        ) : (
          <p>Please log in to send a message.</p>
        )}
        
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
    </div>
  );
}

import React from 'react';
import "../styles/content-page.css";

class AboutUs extends React.Component {
    render() {
        return (
            <div className="page-wrapper-div">
                <div className="about-us-content">
                    <p className="about-us-text">We were inspired to move forward in our lives—now, let us inspire you.</p>
                    <p className="about-us-text">Explore content on our platform that helps unlock your potential. Share your experiences and inspire others to develop theirs!</p>
                    <p className="about-us-text">This platform is our commitment to nurturing potential, together with you.</p>
                </div>
            </div>
        );
    }
}

export default AboutUs;

import React, { useState, useEffect, useContext, useRef  } from 'react';
import CommentPanel from './CommentPanel';
import ConfirmModal from './ConfirmModal';
import Carousel from 'react-elastic-carousel'

import { RiEditBoxLine, RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineYoutube, AiOutlineLink, AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";

import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

import { UserContext } from "./UserProvider";
import {extractVideoIds, extractYouTubeVideoID, saveVideoInfoToSession, getVideoInfoFromSession, fetchTitleAndThumb} from "./videohelper";
import axios from "axios";
import appConfig from "./config.json";
import "./styles/post-box.css";

// this is a class component to display the item details
// shows when the square item is clicked
const RecordDetailsModal = ({ recordItem, onClose }) => {

    const [editMode, setEditMode] = useState(false); // editMode state
    const [showVideo, setShowVideo] = useState(false);
    const [currentVideoURL, setCurrentVideoURL] = useState('');
    const [isConfirmDeleteBoxOpen, setIsConfirmDeleteBoxOpen] = useState(false);
    const [numOfLikedUsers, setNumOfLikedUsers] = useState(0);
    const { loggedInUser, loginUserId, loginUserProfileImg } = useContext(UserContext);
    const [isLiked, setIsLiked] = useState(false);
    const [stepsVideoInfoItems, setStepsVideoInfoItems] = useState([]);

    const confirmDeleteStepTxt = "Are you sure you want to delete this learning experience?";
    const closeBtnRef = useRef(null);
    const carouselRef = useRef();

    const buttonStyle = {
        backgroundColor: isLiked ? '#6B8BFF' : 'initial', // Set to blue if liked, else initial (default)
        color: isLiked ? '#000000' : 'initial', // Set to blue if liked, else initial (default)
    };
    
    const iconStyle = {
        transform: isLiked ? 'scale(1.5)' : 'scale(1)', // Add a little jump effect
        transition: 'transform 0.2s ease', // Apply a smooth transition
    };

    useEffect(() => {
        if (recordItem._id && loginUserId) {
            getIfLiked();
        }
    }, [recordItem._id, loginUserId]);

    useEffect(() => {
        if (recordItem.liked_users) {
            setNumOfLikedUsers(recordItem.liked_users.length);
        }
        const videoIds = extractVideoIds(recordItem);
        //console.log(videoIds);
        if (videoIds.length === 0) {
            return;
        }
        updateLocalVideoInfo(videoIds);
    }, [recordItem]);

    const updateLocalVideoInfo = async (videoIds) => {
        const videoInfoPromises = videoIds.map(async (videoId) => {
            const cachedVideoInfo = getVideoInfoFromSession(videoId);
            //console.log("cachedVideoInfo videoID:" + videoIds[0]);
            //console.log(cachedVideoInfo);

            if (cachedVideoInfo) {
                return cachedVideoInfo;
            } else {
                const videoInfo = await fetchTitleAndThumb(videoId);
                //const thumbnail = await fetchTitleAndThumb(videoId);
                if (videoInfo) {
                    saveVideoInfoToSession(videoId, videoInfo);
                    return videoInfo;
                }
            }
        });
    
        const allVideoInfo = await Promise.all(videoInfoPromises);
        //console.log(allVideoInfo);
        setStepsVideoInfoItems(allVideoInfo.filter(t => t !== undefined));
    };

    const getIfLiked = async () => {
        try {
            // Replace `YOUR_BACKEND_URL` with the actual backend URL
            const response = await axios.get(`${appConfig.SERVER}/records/${recordItem._id}/${loginUserId}/isliked`);
            setIsLiked(response.data.isLiked);
        } catch (error) {
            console.error('Error fetching like status:', error);
        }
    };

    const toggleEditMode = () => {
        setEditMode((prevEditMode) => !prevEditMode); // toggle editMode state
        window.location.href = `/browsing?editId=${recordItem._id}`;
    };

    const onDeleteBtnClick = () => {
        setIsConfirmDeleteBoxOpen(true);
    };

    const onDeleteModalConfirm = async () => {
        setIsConfirmDeleteBoxOpen(false);
        await deleteRecordItem();
        //delete the record here
        if (closeBtnRef.current) {
            closeBtnRef.current.click();
            console.log("deleting below record");
            console.log(recordItem);
        } 
    };

    const deleteRecordItem = async () => {
        try {
          const deleteId = recordItem._id;
          const response = await fetch(`/records/${deleteId}/delete`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }
        } catch (error) {
          console.error('Failed to delete RequestItems:', error);
        }
    };

    const onDeletionModalBoxCancel = () => {
        setIsConfirmDeleteBoxOpen(false);
    }

    const onCloseBtnClick = () => {
        onClose();
    };

    const onLikedBtnClick = async () => {

        setIsLiked(!isLiked);
        if (isLiked) {
            await disLikeRecord();

            setNumOfLikedUsers(numOfLikedUsers-1);

            const userIndex = recordItem.liked_users.indexOf(loginUserId);
            if (userIndex !== -1) {
              recordItem.liked_users.splice(userIndex, 1);
            }
        } else {
            await likeRecord();
            setNumOfLikedUsers(numOfLikedUsers+1);
            recordItem.liked_users.push(loginUserId);
        }

    };

    const likeRecord = async () => {
        const likeRequest = { likingUserId: loginUserId };
        try { 
            const response = await fetch(`/records/${recordItem._id}/like`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(likeRequest)
            });
            const respondData = await response.json();
            return respondData;
        } catch (error) {
            console.log("error occurred: ", error);
        }
    };

    const disLikeRecord = async () => {
        try { 
            const response = await fetch(`/records/${recordItem._id}/dislike`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ dislikingUserId: loginUserId })
            });
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.log("Error occurred: ", error);
        }
    };

    const renderStepItem = (stepItem, stepIndex) => {
        const resItem = stepItem.resources[0];

        if (stepsVideoInfoItems[stepIndex] && stepsVideoInfoItems[stepIndex].thumbUrl === "content") {
            return <>
                <div className='crs-title'>{stepItem.title}</div>
                {/*<img className='crs-thumb-img' alt='step-img' title="youtube video" src={require("./images/content-img.jpg")} onClick={() => onResItemClick(resItem)}/>
                <span class="crs-thumb-tooltip">{stepsVideoInfoItems[stepIndex].title}</span> */} 
                {renderContentItemDiv(stepItem)}
            </>
        }
        else {
            return <>
                {stepsVideoInfoItems[stepIndex] && <>
                <div className='crs-title'>{stepItem.title}</div>
                <img className='crs-thumb-img' alt='step-img' title="youtube video" src={stepsVideoInfoItems[stepIndex].thumbUrl} onClick={() => onResItemClick(resItem)}/>
                <span class="crs-thumb-tooltip">{stepsVideoInfoItems[stepIndex].title}</span> 
                </>}
            </>
        }
    };


    const renderContentItemDiv = (stepItem) => {
        console.log(stepItem);
        const content = stepItem?.resources?.[0]?.content ?? "null content";
        
        return <div className='step-content-div'>{content}</div>
    };


    const onResItemClick = (resItem) => {
        //console.log(resItem);
        if (!resItem) {
            console.error("resource item is null.");
            return;
        }
        //if it is a web page
        if (resItem.type === 'webpage') {
            openUrlInNewTab(resItem.content);
        }
        else if (resItem.type === 'youtube') {
    
            const ytVideoID = extractYouTubeVideoID(resItem.content);
            if (ytVideoID) {
                playVideo("https://www.youtube.com/embed/" + ytVideoID);
            }
        }
    };

    const renderResItemIcon = (resItemType) => {
        if (resItemType === 'youtube') {
            return <AiOutlineYoutube />;
        } 
        else if (resItemType === 'webpage') {
            return <AiOutlineLink />;
        }
        else {
            return <></>;
        }
    };

    /**
     * handler to open the URL in a new window/tab
     */ 
    const openUrlInNewTab = (url) => {
        window.open(url, '_blank');
    };

    /**
     * play video
     */
    const playVideo = (url) => {
        setCurrentVideoURL(url);
        setShowVideo(true);
    };

    /**
     * close video
     */
    const closeVideo =() => {
        setShowVideo(false);
        setCurrentVideoURL("");
    };

    return (
        <>
            <div className='pb-main-dialog'>
                <div class="modal-dialog">
                    <div class="modal-content square-detail-modal-content">
                        {/* the header - user image - title */}
                        <div class="modal-header square-detail-header">
                            <div className="square-detail-header-upper">
                                <div className='auth-header-div'>
                                    <div className="auth-init-round"><img className="auth-init-round" src={recordItem.userPicture} alt='user' /></div>
                                    <div className="auth-name">{recordItem.userName}</div>
                                </div>
                                <button class="square-detail-close-btn" onClick={onCloseBtnClick}><HighlightOffTwoToneIcon fontSize='medium'/></button>
                            </div>
                            <div className="square-detail-header-bottom">
                                <div className='square-title-text'>{recordItem.title}</div>
                            </div>
                        </div>

                        {/* step items */}
                        <div className='learning-records-container'>
                            <Carousel className='crs-container'
                                ref={carouselRef}
                                itemsToShow={1}
                                showArrows={true}
                                pagination={true} >
                                {recordItem.steps.map((stepItem, index) => {
                                    return <div className='crs-div'>
                                                {renderStepItem(stepItem, index)}
                                            </div>
                                })}
                            </Carousel>
                        </div>

                        {/* operation items */}
                        <div class="square-list-res-operation-row">
                            {/*renderLikedText(recordItem)*/}
                            <div className='square-list-like'>{numOfLikedUsers + " users liked this learning experience"}</div>
                            <div className='square-list-btns'>
                            { loginUserId && 
                                (<button className="square-list-res-like-btn" 
                                        style={buttonStyle} 
                                        onClick={()=>onLikedBtnClick()} ><span style={iconStyle}><ThumbUpAltOutlinedIcon fontSize='10px' /></span></button>) } 
                            </div>
                        </div>

                        {/* comments */}
                        {loginUserId ? (<CommentPanel recordId={recordItem._id} commtHost={"record"} ></CommentPanel>) : (<div className='login-see-commts-div'>Please login to see comments.</div>)}
                    </div>
                </div>
            </div>

            {showVideo && (
                <div className="video-popup">
                    <div className="video-wrapper">
                        <iframe src={`${currentVideoURL}`} title={`YouTube video player`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className='close-btn-div'><button className='close-video-btn' onClick={() => closeVideo()}><AiOutlineCloseCircle /></button></div>
                </div>
            )}

            {/* modal box for confirm delete */}
            {isConfirmDeleteBoxOpen && <ConfirmModal onConfirm={onDeleteModalConfirm} onCancel={onDeletionModalBoxCancel} contentText={confirmDeleteStepTxt} />}
        </>
    );
};
  
export default RecordDetailsModal;


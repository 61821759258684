import React, { useState, useContext } from 'react';
import { UserContext } from "../UserProvider";
import { Link } from "react-router-dom"

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';


import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';

import logo from '../images/logo.png';
import '../styles/top-menu.css';

const TopMenu = () => {

    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const { loggedInUser, loginUserId, loginUserProfileImg, profileHasNewNotification } = useContext(UserContext);
    const [searchTerm, setSearchTerm] = useState("");

    const onSearchButtonClick = async () => {
        
        if(searchTerm !== "") {
            var url = '/home?search=' + searchTerm;
            window.location.href = url;
        } else {
            window.location.href = '/home';
        }

    }

    const onSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onKeyInput = (e) => {
        if (e.key === 'Enter') {
            // Trigger your desired action here
            onSearchButtonClick();
            // You can also call a function here to perform search or other actions
        }
    };

    const onLogoClick = () => {
        window.location.href = '/home';
    };

    const getNameAcronym = (str) => {
        // Split the input string by spaces
        const wordsArray = str.split(' ');
    
        // Map over the array, taking the first letter of each word and converting it to uppercase
        const acronym = wordsArray.map(word => word.charAt(0).toUpperCase()).join('');
    
        // Return the final acronym
        return acronym;
    }
    

    return (
        <nav className="navbar gap-2 p-1 small bg-white border shadow-sm">
            <div className='navbar-container'>
                <div className="logo" onClick={onLogoClick}>
                    <img className="navbar-logo" src={logo} alt="Logo" />
                </div>
                <div className="search-box">
                    <input type="text" placeholder="Search Potential" value={searchTerm} onChange={onSearchTermChange} onKeyDown={onKeyInput} />
                    <button className='search-icon' onClick={() => onSearchButtonClick()}><SearchTwoToneIcon fontSize="large" /></button>
                </div>
                <button className="expand-btn" onClick={() => setIsNavExpanded(!isNavExpanded)}>
                    <MenuOpenTwoToneIcon fontSize="large" />
                </button>
                <div className="menu-items">
                    <Link className="menu-link" aria-selected="false" tabIndex="-1" to="/home" >
                        <HomeTwoToneIcon fontSize="large" className="homepage-navbar-icon" />
                        <div>Home</div>
                    </Link>
                    <Link className="menu-link" aria-selected="false" tabIndex="-1" to="/browsing" >
                        <AddBoxTwoToneIcon fontSize="large" className="homepage-navbar-icon" />
                        <div>Create</div>
                    </Link>
                    <Link className="menu-link" aria-selected="false" tabIndex="-1" to="/profile" >
                        {loginUserId && <>
                            <img className="nav-menu-user-img" alt="userProfileImg" referrerPolicy="no-referrer" src={loginUserProfileImg} />
                            <div className="nav-menu-user-name nav-link-text">{loggedInUser}</div>
                            {profileHasNewNotification && <span className="red-dot-top-menu"></span>}
                        </>}
                        {!loginUserId && <>
                            <PortraitTwoToneIcon fontSize="large" className="homepage-navbar-icon" />
                            <div>Profile</div>
                        </>}
                    </Link>
                </div>
            </div>
            { isNavExpanded ? <>
                <ul className='mobile-menu-ul'>
                    <li><Link className="menu-link" aria-selected="false" tabIndex="-1" to="/home" >
                        <HomeTwoToneIcon fontSize="large" className="homepage-navbar-icon" />
                        <div>Home</div>
                    </Link></li>
                    <li><Link className="menu-link" aria-selected="false" tabIndex="-1" to="/browsing" >
                        <AddBoxTwoToneIcon fontSize="large" className="homepage-navbar-icon" />
                        <div>Create</div>
                    </Link></li>
                    <li><Link className="menu-link profile-usrname" aria-selected="false" tabIndex="-1" to="/profile" >
                        {loginUserId && <>
                            <img className="nav-menu-user-img" alt="userProfileImg" referrerPolicy="no-referrer" src={loginUserProfileImg} />
                            <div className="nav-menu-user-name nav-link-text">{getNameAcronym(loggedInUser)}</div>
                            {profileHasNewNotification && <span className="red-dot-top-menu"></span>}
                        </>}
                        {!loginUserId && <>
                            <PortraitTwoToneIcon fontSize="large" className="homepage-navbar-icon" />
                            <div>Profile</div>
                        </>}
                    </Link></li>
                </ul>
            </> : <></> }
        </nav>
    );
};

export default TopMenu;

/**
 * <a href="#" onClick={() => setIsNavExpanded(false)}>Home</a>
          <a href="#" onClick={() => setIsNavExpanded(false)}>Create</a>
          <a href="#" onClick={() => setIsNavExpanded(false)}>Profile</a>
 */
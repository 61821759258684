import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactUs from './ContactUs';

export default function Footer() {
  const [showContactUs, setShowContactUs] = useState(false);

  const handleContactUsClick = () => {
    setShowContactUs(true);
  };

  const handleClose = () => {
    setShowContactUs(false);
  };

  return (
    <div className="container">
      <footer className="py-3">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          {/* <li className="nav-item-footer"><Link to="/sitemap" className="nav-link px-2 text-muted">SiteMap</Link></li>  */}
          <li className="nav-item-footer"><Link to="/privacy" className="nav-link px-2 text-muted">Privacy Policy</Link></li>
          {/* <li className="nav-item-footer"><Link to="/termofuse" className="nav-link px-2 text-muted">Term of Use</Link></li>  */}
          <li className="nav-item-footer"><Link to="/aboutus" className="nav-link px-2 text-muted">About Us</Link></li>
          <li className="nav-item-footer"><button onClick={handleContactUsClick} className="nav-link px-2 text-muted btn-link">Contact Us</button></li>
        </ul>
        {/* <p className="text-center text-muted">© 2022 Potential</p> */}
      </footer>
      {showContactUs && <ContactUs onClose={handleClose} />}
    </div>
  );
}

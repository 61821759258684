// utils.js

// define the helper function here

export function timeGap(timestamp1, timestamp2) {

    let diffInMs = timestamp1 - timestamp2;
    let diffInMin = Math.floor(diffInMs / (1000 * 60));

    if (diffInMin < 60) {
      return `${Math.floor(diffInMin)} minutes ago`;
    } else if (diffInMin < 1440) {
      return `${Math.floor(diffInMin / 60)} hours ago`;
    } else if (diffInMin < 10080) {
      return `${Math.floor(diffInMin / 1440)} days ago`;
    } else if (diffInMin < 525600) {
      return `${Math.floor(diffInMin / 10080)} weeks ago`;
    } else {
      return `${Math.floor(diffInMin / 525600)} years ago`;
    }
}

import React from 'react';
import "./styles/loadingAnimation.css";

const LoadingAnimation = () => {
  return (
    <div className="loading-animation">
      <div className="loading-circle"></div>
      <p>deleting your account...</p>
    </div>
  );
};

export default LoadingAnimation;

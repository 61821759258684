import React from 'react';

function ConfirmModal({ onConfirm, onCancel, contentText }) {
    return (
      <div className='confirm-modalbox'>
        <div className='confirm-innerbox'>
          <p>{contentText}</p>
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    );
  }

  export default ConfirmModal;